<app-storyblok-root
  [story]="story$ | async"
  [blurOverlay]="blurOverlay$ | async"
  [darkHeader]="darkHeader$ | async"
  [hasHomeTeaser]="hasHomeTeaser$ | async"
  [prefooter]="prefooter$ | async"
  [topBlur]="topBlur$ | async"
  [topBlurMaxHeight]="topBlurMaxHeight$ | async"
  [verticalDottedLines]="verticalDottedLines$ | async"
  [extraDataMap]="extraDataMap$ | async"
></app-storyblok-root>
