import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { of, switchMap, take } from 'rxjs';
import { PICFLOW_STORYBLOK } from '../picflow-storyblok';
import { PricingService } from '../services/pricing.service';

export const storyblokSeatAddonPricesResolver: ResolveFn<unknown> = route => {
  const pricingService = inject(PricingService);
  const storyblokService = inject(PICFLOW_STORYBLOK);

  return storyblokService.getUniqueComponentsList(route).pipe(
    switchMap(components => {
      if (components.has('ProductComparisonList')) {
        return pricingService.getPrices('seat_addon').pipe(take(1));
      }
      return of(undefined);
    })
  );
};
