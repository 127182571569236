import { Injectable, inject } from '@angular/core';
import { from, map } from 'rxjs';
import { Canny } from '../canny';
import { loadScript } from '../lib/load-script';
import { ObservableDataLoaderService } from './observable-data-loader.service';

@Injectable({
  providedIn: 'root',
})
export class CannyService implements Canny {
  private loader = inject(ObservableDataLoaderService);

  public get() {
    return this.loader.get('canny', () => {
      window['Canny'] = function () {
        window['Canny'].q.push([window, document]);
      };
      window['Canny'].q = [];
      return from(loadScript('https://canny.io/sdk.js', { ignoreErrors: true })).pipe(map(() => window['Canny']));
    });
  }
}
