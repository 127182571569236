import { Routes, ɵEmptyOutletComponent } from '@angular/router';
import { homeRedirectGuard } from '../guards/home-redirect.guard';
import { storyblokPreventNavInEditModeGuard } from './guards/storyblok-prevent-nav-in-edit-mode.guard';
import { storyblokStoryGuard } from './guards/storyblok-story.guard';
import { storyblokBackendPricesResolver } from './storyblok-backend-prices.resolver';
import { storyblokCompetitorsResolver } from './storyblok-competitors.resolver';
import { storyblokCustomRatingsResolver } from './storyblok-custom-ratings.resolver';
import { storyblokFooterResolver } from './storyblok-footer.resolver';
import { storyblokHeaderResolver } from './storyblok-header.resolver';
import { StoryblokPageComponent } from './storyblok-page/storyblok-page.component';
import { storyblokPricingBoxContentResolver } from './storyblok-pricing-box-content.resolver';
import { storyblokPricingTechnicalLinksResolver } from './storyblok-pricing-technical-links.resolver';
import { storyblokProductComparisonFeatureGroupsResolver } from './storyblok-product-comparison-feature-groups.resolver';
import { storyblokProductComparisonFeaturesResolver } from './storyblok-product-comparison-features.resolver';
import { storyblokProductsResolver } from './storyblok-products.resolver';
import { storyblokPromotionResolver } from './storyblok-promotion.resolver';
import { storyblokRelationshipsResolver } from './storyblok-relationships.resolver';
import { storyblokSeatAddonPricesResolver } from './storyblok-seat-addon-prices.resolver';
import { storyblokStoryResolver } from './storyblok-story.resolver';
import { storyblokToolsConvertFormatsResolver } from './storyblok-tools-convert-formats.resolver';

export const routes: Routes = [
  {
    path: 'home',
    canActivate: [homeRedirectGuard],
    component: ɵEmptyOutletComponent,
  },
  {
    path: '**',
    component: StoryblokPageComponent,
    canActivate: [storyblokStoryGuard],
    canDeactivate: [storyblokPreventNavInEditModeGuard],
    resolve: {
      story: storyblokStoryResolver,
      backendPrices: storyblokBackendPricesResolver,
      seatAddonPrices: storyblokSeatAddonPricesResolver,
      pricingBoxContent: storyblokPricingBoxContentResolver,
      products: storyblokProductsResolver,
      productComparisonFeatureGroups: storyblokProductComparisonFeatureGroupsResolver,
      productComparisonFeatures: storyblokProductComparisonFeaturesResolver,
      pricingTechnicalLinks: storyblokPricingTechnicalLinksResolver,
      promotion: storyblokPromotionResolver,
      relationships: storyblokRelationshipsResolver,
      toolConversionFormatList: storyblokToolsConvertFormatsResolver,
      customRatings: storyblokCustomRatingsResolver,
      competitors: storyblokCompetitorsResolver,
      footer: storyblokFooterResolver,
      header: storyblokHeaderResolver,
    },
  },
];
