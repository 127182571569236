import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { PICFLOW_STORYBLOK } from '../picflow-storyblok';
import { ConversionFormat } from '../tools/convert/templates/conversion-template/conversion-template.component';

export const storyblokToolsConvertFormatsResolver: ResolveFn<Observable<ConversionFormat[]>> = route => {
  const storyblokService = inject(PICFLOW_STORYBLOK);

  return storyblokService.getUniqueComponentsList(route).pipe(
    switchMap(components => {
      if (components.has('ConvertFromFormatList')) {
        return storyblokService.getStories({ content_type: 'ImageFormat' }) as Observable<ConversionFormat[]>;
      }
      return of(undefined);
    })
  );
};
