import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, NgZone, PLATFORM_ID, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { ContextMenuGlobalComponent } from './components/context-menu-global/context-menu-global.component';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import { DialogsComponent } from './components/dialogs/dialogs.component';
import { GoogleOneTapComponent } from './components/google-one-tap/google-one-tap.component';
import { RecordDebugComponent } from './components/record-debug/record-debug.component';
import { ToastsComponent } from './components/toasts/toasts.component';
import { TooltipGlobalComponent } from './components/tooltip-global/tooltip-global.component';
import { getFullPath } from './lib/get-full-path';
import { sleep } from './lib/sleep';
import { AppConfigService } from './providers/config.service';
import { AppExtraLoadingService } from './services/app-extra-loading.service';
import { BrowserService } from './services/browser.service';
import { CookieBannerService } from './services/cookie-banner.service';
import { StateService } from './services/state.service';
import { RouteLoaderComponent } from './shared/components/route-loader/route-loader.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    DialogsComponent,
    ToastsComponent,
    ContextMenuGlobalComponent,
    TooltipGlobalComponent,
    RouteLoaderComponent,
    CookieBannerComponent,
    GoogleOneTapComponent,
    RecordDebugComponent,
  ],
})
export class AppComponent {
  private state = inject(StateService);
  private router = inject(Router);
  private platformId = inject<string>(PLATFORM_ID);
  private route = inject(ActivatedRoute);
  private browserService = inject(BrowserService);
  private http = inject(HttpClient);
  private config = inject(AppConfigService);
  private zone = inject(NgZone);
  private cookieBannerService = inject(CookieBannerService);
  private appExtraLoadingService = inject(AppExtraLoadingService);
  routeLoading = signal(false);
  showCookieBanner = toSignal(this.cookieBannerService.show$);
  showTooltips = signal(false);
  showToasts = signal(false);
  showDialogs = signal(false);
  showContextMenus = signal(false);
  showRouteLoader = signal(false);
  recordDebug = signal(false);

  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      this.appExtraLoadingService.load$.subscribe(load => {
        if (load === 'contextMenus') {
          this.showContextMenus.set(true);
        }
        if (load === 'dialogs') {
          this.showDialogs.set(true);
        }
        if (load === 'toasts') {
          this.showToasts.set(true);
        }
        if (load === 'tooltips') {
          this.showTooltips.set(true);
        }
      });

      if (location.search.includes('debugMode')) {
        this.recordDebug.set(true);
      }
    }

    if (this.config.env !== 'local') this.loadConsoleMessage();

    if (isPlatformServer(this.platformId)) this.routeLoading.set(false);

    let previousUrl = '';
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // No route animation when switching to the asset view
        const galleryToAsset =
          this.browserService.urlIsGalleryUrl(previousUrl) && this.browserService.urlIsAssetUrl(event.url);
        const assetToGallery =
          this.browserService.urlIsAssetUrl(previousUrl) && this.browserService.urlIsGalleryUrl(event.url);

        if (!galleryToAsset && !assetToGallery) {
          this.routeLoading.set(true);
          this.showRouteLoader.set(true);
        }
      }
      if (event instanceof NavigationEnd) {
        this.routeLoading.set(false);
        if (!previousUrl) {
          this.cookieBannerService.handleCookieBanner();
        } else {
          this.cookieBannerService.hide();
        }
        this.state.pathHistory.push('/' + getFullPath(this.route.snapshot).join('/'));
        previousUrl = event.urlAfterRedirects;
      }
      if (event instanceof NavigationCancel) this.routeLoading.set(false);
      if (event instanceof NavigationError) this.routeLoading.set(false);
    });
  }

  private loadConsoleMessage() {
    if (isPlatformServer(this.platformId)) return;
    this.zone.runOutsideAngular(async () => {
      await sleep(1000 * 2);
      try {
        const message = await lastValueFrom(this.http.get('/assets/console-comment.txt', { responseType: 'text' }));
        console.log(message);
      } catch {}
    });
  }
}
