import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { of, switchMap, take } from 'rxjs';
import { PICFLOW_STORYBLOK } from '../picflow-storyblok';
import { PricingService } from '../services/pricing.service';

export const storyblokBackendPricesResolver: ResolveFn<unknown> = route => {
  const pricingService = inject(PricingService);
  return inject(PICFLOW_STORYBLOK)
    .getUniqueComponentsList(route)
    .pipe(
      switchMap(components => {
        if (components.has('PricingBoxList') || components.has('ProductComparisonList')) {
          return pricingService.getPrices('main').pipe(take(1));
        }
        return of(undefined);
      })
    );
};
