import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
// import { Components } from './components';
import { PICFLOW_STORYBLOK } from '../picflow-storyblok';
import { Competitor } from '../services/compare-replace.service';

export const storyblokCompetitorsResolver: ResolveFn<Observable<Competitor[]>> = route => {
  const storyblokService = inject(PICFLOW_STORYBLOK);

  return storyblokService.getUniqueComponentsList(route).pipe(
    switchMap(components => {
      if (components.has('CompetitorList')) {
        return storyblokService.getStories({ content_type: 'Competitor' }) as Observable<Competitor[]>;
      }
      return of(undefined);
    })
  );
};
