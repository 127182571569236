import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GrowthBookExperimentService {
  public onExperimentViewed = new BehaviorSubject<{
    name: string;
    featureId: string;
    resultValue: string;
  }>(null);
}
