<app-google-one-tap></app-google-one-tap>
<router-outlet></router-outlet>
<!-- e2e test doesn't like it when this is loaded later-->
@defer (when showTooltips()) {
  <app-tooltip-global></app-tooltip-global>
} @error {}
@defer (when showToasts()) {
  <app-toasts></app-toasts>
} @error {}
@defer (when showDialogs()) {
  <app-dialogs></app-dialogs>
} @error {}
@defer (when showContextMenus()) {
  <app-context-menu-global></app-context-menu-global>
} @error {}
@defer (when showCookieBanner()) {
  @if (showCookieBanner()) {
    <app-cookie-banner></app-cookie-banner>
  }
} @error {}
@defer (when showRouteLoader()) {
  <app-route-loader [active]="routeLoading()"></app-route-loader>
} @error {}
@defer (when recordDebug()) {
  <app-record-debug></app-record-debug>
} @error {}
