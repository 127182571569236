import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Response } from 'express';
import { RESPONSE } from '../../express.tokens';
import { StateService } from '../services/state.service';

@Injectable({
  providedIn: 'root',
})
export class NotFoundGuard {
  private state = inject(StateService);
  private platformId = inject<string>(PLATFORM_ID);
  private response = inject<Response>(RESPONSE, { optional: true });

  async canActivate(): Promise<UrlTree | boolean> {
    if (isPlatformServer(this.platformId)) this.response?.status(404);
    this.state.nextPageIsNotFound = true;
    return true;
  }
}
