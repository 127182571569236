import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Injectable, NgZone, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, filter, take } from 'rxjs';
import { generateUuid } from '../lib/generate-uuid';
import { getAllRouteData } from '../lib/get-all-route-data';
import { isIframe } from '../lib/is-iframe';
import { loadScript } from '../lib/load-script';
import { PicflowAnalytics } from '../picflow-analytics';
import { AppConfigService } from '../providers/config.service';
import { BrowserService } from './browser.service';
import { GrowthBookExperimentService } from './growth-book-experiment.service';
import { MetaService } from './meta.service';
import { StateService } from './state.service';
import { StorageService } from './storage.service';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any;
    dataLayer: { [key: string]: unknown }[];
    Intercom: (action: string, ...args: unknown[]) => void;
    intercomSettings: { [key: string]: unknown };
    attachEvent: (event: string, callback: () => void) => void;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService implements PicflowAnalytics {
  private router = inject(Router);
  private config = inject(AppConfigService);
  private state = inject(StateService);
  private metaService = inject(MetaService);
  private route = inject(ActivatedRoute);
  private cookies = inject(CookieService);
  private storage = inject(StorageService);
  private browserService = inject(BrowserService);
  private zone = inject(NgZone);
  private growthbookExperimentService = inject(GrowthBookExperimentService);
  private platformId = inject<string>(PLATFORM_ID);
  private initted = false;
  private lastValues = {};
  private isIframe: boolean;
  private currentUrl: string;
  public userTraits: { [key: string]: number | string } = {}; // expected to be set by the loggedin analytics service
  public lastUrl = '';
  public lastButtonClicked: { ctaName: string; ctaText: string; ctaIcon: string; ctaLocation: string };

  private growthbookExperiments$ = this.growthbookExperimentService.onExperimentViewed
    .pipe(filter(Boolean))
    .subscribe(data => {
      if (this.growthbookExperiments.some(e => e.name === data.name)) return;
      this.growthbookExperiments.push(data);
      this.track('experiment_viewed', false, {
        experiment_name: data.name,
        feature_id: data.featureId,
        result_value: data.resultValue,
      });
    });

  private growthbookExperiments: {
    name: string;
    featureId: string;
    resultValue: string;
  }[] = [];

  private firstInteraction$ = new BehaviorSubject(false);

  event$ = new BehaviorSubject<{ event: string; properties: { [key: string]: unknown } }>(undefined);

  constructor() {
    if (isPlatformServer(this.platformId)) return;
    this.setupFirstInteractionLogic();
    this.isIframe = isIframe();

    this.zone.runOutsideAngular(() => {
      this.segmentInit();
      this.tagmanagerInit();
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          take(1)
        )
        .subscribe(() => this.init());
    });

    this.router.events.subscribe(async event => {
      if (this.state.impersonating) return;
      if (event instanceof NavigationStart) {
        this.extractAndSaveUtmParams(event.url);
        this.extractAndSaveTrackingParams(event.url);
        this.state.nextPageIsNotFound = false;
      } else if (event instanceof NavigationEnd) {
        if (this.lastUrl.split('?')[0].split('#')[0] === event.urlAfterRedirects.split('?')[0].split('#')[0]) return;

        this.lastUrl = event.urlAfterRedirects.split('email=')[0];
        const path = event.urlAfterRedirects.split('email=')[0];
        const title = await this.metaService.getTitle(path);
        console.debug('PAGEVIEW:', title);

        this.currentUrl = location.origin + path;

        this.extractAndSaveFirstUrl(path);

        const data = getAllRouteData(this.route.snapshot);
        let gallery_id: string;
        let image_id: string;
        let tenant_id: string;
        if (data.gallery) {
          gallery_id = data.gallery.id;
          if (data.gallery.tenant) tenant_id = data.gallery.tenant?.id || data.gallery.tenant;
        }
        if (data.image) image_id = data.image.id;
        if (data.data?.image) image_id = data.data.image.id;

        window.Intercom?.('boot');
        if (!this.config.isTenantDomain) {
          this.pushToDataLayer(this.mapTagmanagerData(this.userTraits), true);
        }
        this.pushToDataLayer({
          event: 'picflow_page_view',
          page_location: location.origin + path,
          page_title: title,
          gallery_id,
          image_id,
          tenant_id,
        });

        this.segmentPage(title, path);
      }
    });
  }

  private init() {
    if (this.initted) return;
    this.initted = true;
    this.segmentLoad();
    this.tagmanagerLoad();
    this.cloudflareAnalyticsLoad();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private mapTagmanagerData(_data: { [key: string]: any }) {
    const data = Object.assign({}, _data);

    // remove tracking parameters
    delete data.li_fat_id;
    delete data.li_fat_id;
    delete data.epik;
    delete data.gclid;
    delete data.gbraid;
    delete data.wbraid;
    delete data.ttclid;
    delete data.msclkid;
    delete data.twclid;
    delete data.fbclid;
    delete data.fbc;
    delete data.fbp;

    // clarify button fields
    data.button_name = data.name;
    data.button_location = data.location;
    delete data.name;
    delete data.location;

    return data;
  }

  private extraMixpanelProperties() {
    return {
      devicePixelRatio: window.devicePixelRatio,
      resolution: `${window.screen.width}x${window.screen.height}`,
      tenantId: this.state.visitingTenant?.id ?? this.state.activeTenant?.id ?? this.state.sharedWithMeActiveTenant?.id,
    };
  }

  private getAbTestTraits() {
    const abTests: { [key: string]: string } = {};
    this.growthbookExperiments.forEach(e => (abTests[e.name] = e.resultValue || '__control__'));
    return { abTests };
  }

  public setLastButtonClicked(ctaName: string, ctaText: string, ctaIcon: string, ctaLocation: string) {
    this.lastButtonClicked = { ctaName, ctaText, ctaIcon, ctaLocation };
  }

  public track(
    event: string,
    isImportant = true,
    extraProperties: {
      [key: string]:
        | string
        | number
        | boolean
        | { [key: string]: string | number | boolean }
        | { [key: string]: string | number | boolean }[];
    } = {}
  ) {
    if (this.state.impersonating) return;
    if (!event || typeof event !== 'string') {
      console.warn('Track event is not a string', event);
      return;
    }
    console.debug('EVENT:', event, extraProperties);
    if (!isPlatformBrowser(this.platformId)) return;

    if (isImportant) {
      window.Intercom?.('trackEvent', event);
    }
    const order_id = (extraProperties.order_id || `picflow_${generateUuid()}`) as string;

    const properties = {
      ...this.userTraits,
      ...this.extraMixpanelProperties(),
      ...extraProperties,
      ...this.getAbTestTraits(),
      order_id,
      current_url: this.currentUrl,
    };
    this.segmentTrack(event, properties);
    if (!this.config.isTenantDomain) {
      this.pushToDataLayer(this.mapTagmanagerData(properties), true);
      this.pushToDataLayer({ event });
    }

    if (event === 'checklist item done' && extraProperties.name === 'uploadAssets') {
      this.track('First Upload', false, extraProperties);
      this.track('onboarding step done: first upload', false, extraProperties);
    }

    this.event$.next({ event, properties });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async segmentTrack(event: string, params: { [key: string]: any }) {
    try {
      window.analytics?.track(event, params);
    } catch {
      console.warn('Unable to send segment track');
    }
  }

  private async segmentPage(title: string, path: string) {
    const isAssetPage = path && this.browserService.urlIsAssetUrl(path);
    if (isAssetPage) return;
    try {
      const url = window.location.origin + path;
      window.analytics?.page(title, {
        name: title,
        title,
        url,
        path,
        notFound: this.state.nextPageIsNotFound,
        ...this.userTraits,
        ...this.extraMixpanelProperties(),
        ...this.getAbTestTraits(),
        ...(this.lastButtonClicked ?? {}),
      });
    } catch (err) {
      console.warn('Unable to send segment page', err);
    }
  }

  public reportKeyboardShortcut(name: string, location: string, extra: { [key: string]: string | number } = {}) {
    this.track('keyboard_shortcut', false, { name, location, ...extra });
  }

  public showIntercom() {
    this.intercomInit();
    this.track('open_chat');
    window.Intercom?.('show');
  }

  public intercomInit() {
    if (window.Intercom) return console.debug('Not loading intercom was already loaded');
    if (this.config.env === 'local' && this.config.origin !== 'http://127.0.0.1:4200') {
      return console.debug('Not loading intercom on local as it doesnt work anyway');
    }
    if (this.isIframe) return;

    if (!window.intercomSettings) {
      window.intercomSettings = {
        app_id: this.config.intercomAppId,
        hide_default_launcher: true,
      };
    }

    const i = function () {
      // eslint-disable-next-line prefer-rest-params
      i['c'](arguments);
    };
    i['q'] = [];
    i['c'] = function (args) {
      i['q'].push(args);
    };
    window.Intercom = i;

    loadScript('https://widget.intercom.io/widget/' + this.config.intercomAppId, { ignoreErrors: true });
  }

  private async tagmanagerInit() {
    window.dataLayer = [];

    function gtag() {
      // eslint-disable-next-line prefer-rest-params , @typescript-eslint/no-explicit-any
      (window.dataLayer as any).push(arguments);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (gtag as any)('consent', 'default', {
      ad_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      analytics_storage: 'granted',
    });

    window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  }

  private cloudflareAnalyticsLoad() {
    if (location.search.includes('noBeacon')) return;
    if (this.browserService.getTenantCustomDomain()) return;
    if (this.isIframe) return;
    if (this.config.env === 'local') return;
    loadScript('https://static.cloudflareinsights.com/beacon.min.js', {
      ignoreErrors: true,
      attributes: {
        'data-cf-beacon': `{"token": "e66e38c9e77944d3aac7a496d60a2041"}`,
      },
    });
  }

  private async tagmanagerLoad() {
    if (this.config.env !== 'production') return;
    if (this.browserService.isTenantDomain()) return; // Don't load tagmanager on tenant domains
    if (this.isIframe) return;
    if (location.search.includes('noTagManager')) return;

    if (this.state.activeTenant?.id_stripe_customer) {
      window.dataLayer.push({ event: 'start_pw', pw_user_id: this.state.activeTenant?.id_stripe_customer });
    } else {
      window.dataLayer.push({ event: 'start_pw', pw_user_email: this.state.user?.email });
    }

    this.firstInteraction$.pipe(filter(Boolean), take(1)).subscribe(() => {
      loadScript(
        'https://measure.picflow.com/gtm.js?id=' + this.config.gtmWorkspaceId + this.config.gtmEnvironmentExtra
      );
    });
  }

  hasSetFirstUrl = false;
  private setFirstUrlFromClientDomain(url: string) {
    if (this.hasSetFirstUrl) return;
    this.hasSetFirstUrl = true;
    if (!this.browserService.isTenantDomain()) return;

    const iframe = document.createElement('iframe');
    iframe.src = `${this.config.correctPicflowUrl}/assets/set-first-path.html#${url}`;
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
  }

  private extractAndSaveFirstUrl(url: string) {
    if (url.includes('/auth/authorize')) return;
    if (!this.storage.get('firstUrl')) {
      this.storage.set('firstUrl', { time: Date.now(), url: location.origin + url });
    }
    this.setFirstUrlFromClientDomain(location.origin + url);
  }

  private extractAndSaveUtmParams(url: string) {
    if (!this.storage.has('firstReferrer') && document.referrer) {
      this.storage.set('firstReferrer', { time: Date.now(), referrer: document.referrer });
    }

    const searchString = url.split('?')[1];
    if (!searchString || !searchString.includes('utm_')) return;
    const utmSearchTerms: { key: string; value: string | number }[] = searchString
      .split('&')
      .filter(s => s.startsWith('utm_'))
      .map(s => ({ key: s.split('=')[0].split('utm_')[1], value: decodeURIComponent(s.split('=')[1]) }))
      .filter(s => ['source', 'medium', 'campaign', 'id', 'term', 'content'].includes(s.key));

    if (!utmSearchTerms.length) return;

    utmSearchTerms.push({ key: 'time', value: Date.now() });

    this.storage.set('lastUtm', utmSearchTerms);
    if (!this.storage.has('firstUtm')) this.storage.set('firstUtm', utmSearchTerms);
  }

  private getQueryParam(key: string, url: string) {
    const searchString = url.split('?')[1]?.split('#')[0];
    return searchString
      ?.split('&')
      .find(s => s.startsWith(key))
      ?.split('=')[1];
  }

  private extractAndSaveTrackingParams(url: string) {
    const trackingParams = {
      li_fat_id: this.getQueryParam('li_fat_id', url), // linkedin
      epik: this.getQueryParam('epik', url), // linkedin
      gclid: this.getQueryParam('gclid', url), // google
      gbraid: this.getQueryParam('gbraid', url), // google
      wbraid: this.getQueryParam('wbraid', url), // google
      ttclid: this.getQueryParam('ttclid', url), // tiktok
      msclkid: this.getQueryParam('msclkid', url), // bing
      twclid: this.getQueryParam('twclid', url), // twitter
      fbclid: this.getQueryParam('fbclid', url), // facebook
      fbc: this.cookies.get('_fbc'), // facebook
      fbp: this.cookies.get('_fbp') || undefined, // facebook
    };
    if (Object.values(trackingParams).some(Boolean)) {
      const params = this.getTrackingParams();
      for (const key in trackingParams) {
        if (trackingParams[key]) params[key] = trackingParams[key];
      }
      this.storage.set('trackingParams', params);
    }
  }

  public getTrackingParams() {
    // googleClickParams is for backwards compatibility
    const params = this.storage.get('trackingParams') || this.storage.get('googleClickParams') || {};

    // Only one of these can be set at a time
    if (params.gclid) {
      delete params.gbraid;
      delete params.wbraid;
    } else if (params.gbraid) {
      delete params.gclid;
      delete params.wbraid;
    } else if (params.wbraid) {
      delete params.gclid;
      delete params.gbraid;
    }

    return params;
  }

  public getFirstUrl(): string {
    return this.storage.get('firstUrl')?.url;
  }

  private segmentInit() {
    // Waiting a bit after page load to let the page contents load before loading all the tracking scripts
    // Note: Putting this to less than 1.5s will cause lighthouse to count this as extra time to interactive
    window.analytics = [];
    window.analytics.methods = [
      // 'trackSubmit',
      // 'trackClick',
      // 'trackLink',
      // 'trackForm',
      'pageview',
      'identify',
      // 'reset',
      // 'group',
      'track',
      // 'ready',
      // 'alias',
      // 'debug',
      'page',
      // 'once',
      // 'off',
      // 'on',
      // 'addSourceMiddleware',
      // 'addIntegrationMiddleware',
      // 'setAnonymousId',
      // 'addDestinationMiddleware',
    ];
    window.analytics.factory = function (method) {
      return function () {
        // eslint-disable-next-line prefer-rest-params
        const args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        window.analytics.push(args);
        return window.analytics;
      };
    };
    // For each of our methods, generate a queueing stub.
    for (let i = 0; i < window.analytics.methods.length; i++) {
      const key = window.analytics.methods[i];
      window.analytics[key] = window.analytics.factory(key);
    }
    window.analytics.load = (key, e) => {
      loadScript('https://analytics.picflow.com/analytics.js/v1/' + key + '/analytics.min.js', {
        ignoreErrors: true,
      });
      window.analytics._loadOptions = e;
    };
    window.analytics._writeKey = this.config.segmentKey;
    window.analytics.SNIPPET_VERSION = '4.15.3';
  }

  private async segmentLoad() {
    if (location.search.includes('noSegment')) return;
    // await sleep(thirdPartyScriptDelay);
    // if (this.isIframe) return; https://app.asana.com/0/1200658527198468/1206010622378261/f
    window.analytics.load?.(this.config.segmentKey); // load is not defined in unit tests
  }

  private pushToDataLayer(obj: { [key: string]: string | number }, ignoreDuplicates = false) {
    if (ignoreDuplicates) {
      const sameKeys = Object.keys(obj).filter(key => this.lastValues[key] === obj[key]);
      sameKeys.forEach(key => delete obj[key]);
      if (Object.keys(obj).length === 0) return;
      this.lastValues = { ...this.lastValues, ...obj };
    }
    if (Object.keys(obj).length === 0) return;
    window.dataLayer.push(obj);
  }

  private setupFirstInteractionLogic() {
    const interactionClick = () => {
      this.firstInteraction$.next(true);
      window.removeEventListener('click', interactionClick, true);
      window.removeEventListener('scroll', interactionScroll, true);
    };

    const interactionScroll = () => {
      this.firstInteraction$.next(true);
      window.removeEventListener('click', interactionClick, true);
      window.removeEventListener('scroll', interactionScroll, true);
    };

    window.addEventListener('click', interactionClick, true);
    window.addEventListener('scroll', interactionScroll, true);
  }
}
