import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BrowserService } from '../services/browser.service';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class PromoSaveGuard {
  private browserService = inject(BrowserService);
  private storage = inject(StorageService);
  private platformId = inject<string>(PLATFORM_ID);

  async canActivate(next: ActivatedRouteSnapshot): Promise<true> {
    if (isPlatformServer(this.platformId)) return true;
    const loggedIn = this.browserService.getCookies()?.['picflow-loggedin'] === 'true';
    if (loggedIn) return true;

    const promoCode = next.queryParams.promo;
    if (promoCode) this.storage.set('promo', promoCode);
  }
}
