import { isPlatformServer } from '@angular/common';
import { ChangeDetectionStrategy, Component, PLATFORM_ID, ViewEncapsulation, inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs';
import { ScreenSizes } from '../../classes/screen-sizes.class';
import { getFullPath } from '../../lib/get-full-path';
import { GoogleOneTapService } from '../../services/google-one-tap.service';

@Component({
  selector: 'app-google-one-tap',
  standalone: true,
  imports: [],
  templateUrl: './google-one-tap.component.html',
  styleUrl: './google-one-tap.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleOneTapComponent {
  private googeOneTapService = inject(GoogleOneTapService);
  private platformId = inject(PLATFORM_ID);
  private route = inject(ActivatedRoute);
  topSpacing = signal<number>(undefined);

  private sub$ = this.googeOneTapService.showOneTap$.pipe(filter(Boolean)).subscribe(() => {
    this.topSpacing.set(this.getOneTapTopSpacing());
  });

  private getOneTapTopSpacing() {
    if (isPlatformServer(this.platformId)) return 5;
    if (ScreenSizes.screenSize() === 'mobile') return 10;
    const path = getFullPath(this.route.snapshot);
    if (path[0] === 'auth') return 5;
    return 70;
  }
}
