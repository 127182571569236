import { Routes, ɵEmptyOutletComponent } from '@angular/router';
import { growthBookGuard } from './guards/growth-book.guard';
import { NotFoundGuard } from './guards/not-found.guard';
import { TenantAuthGuard } from './guards/tenant-auth.guard';

const allRoutes: Routes = [
  {
    path: 'folder',
    loadChildren: () => import('./view-folder/routes').then(m => m.routes),
    data: { noIndex: true },
  },
  {
    path: '',
    loadChildren: () => import('./view-gallery/routes').then(m => m.routes),
    data: { noIndex: true },
  },
  {
    path: 'auth/**',
    component: ɵEmptyOutletComponent,
    canActivate: [TenantAuthGuard],
  },
  {
    path: '**',
    canActivate: [NotFoundGuard],
    loadComponent: () => import('./pages/not-found-page/not-found-page.component').then(m => m.NotFoundPageComponent),
    data: { noIndex: true },
  },
];

export const tenantRoutes: Routes = [
  {
    path: '',
    children: allRoutes,
    runGuardsAndResolvers: 'always',
    canActivate: [growthBookGuard],
  },
];
