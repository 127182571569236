import { inject } from '@angular/core';
import { Route, UrlSegment } from '@angular/router';
import { forkJoin, map } from 'rxjs';
import { growthBookGuard } from '../guards/growth-book.guard';
import { PICFLOW_STORYBLOK } from '../picflow-storyblok';

export const templateCanMatch = (route: Route, segments: UrlSegment[]) => {
  const storyblokService = inject(PICFLOW_STORYBLOK);
  const path = segments.join('/');

  return forkJoin([storyblokService.getStory(path), growthBookGuard()]).pipe(
    map(([story]) => {
      if (!story) return true;
      if (story.story.slug.endsWith('-template')) return true;
      return false;
    })
  );
};
