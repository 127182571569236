import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { from, map, of, switchMap } from 'rxjs';
import { PICFLOW_STORYBLOK } from '../picflow-storyblok';

export const storyblokProductComparisonFeatureGroupsResolver: ResolveFn<unknown> = route => {
  const storyblokService = inject(PICFLOW_STORYBLOK);

  return storyblokService.getUniqueComponentsList(route).pipe(
    switchMap(components => {
      if (components.has('ProductComparisonList')) {
        return from(storyblokService.getStory('technical/product-comparison-config', route)).pipe(
          map(resp => resp && resp.story.content.featureGroups)
        );
      }
      return of(undefined);
    })
  );
};
