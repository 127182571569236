import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { getFullPath } from '../lib/get-full-path';
import { BrowserService } from '../services/browser.service';
import { RedirectService } from '../services/redirect.service';

@Injectable({
  providedIn: 'root',
})
export class TenantAuthGuard {
  private redirectService = inject(RedirectService);
  private browserService = inject(BrowserService);

  async canActivate(next: ActivatedRouteSnapshot): Promise<UrlTree | boolean> {
    const path = getFullPath(next);
    const query = next.queryParams;

    let url = this.browserService.getCorrectPicflowUrl();
    if (path.length) url += `/${path.join('/')}`;
    const queryString = new URLSearchParams(query).toString();
    if (queryString) url += `?${queryString}`;

    return this.redirectService.externalRedirectTo(url);
  }
}
