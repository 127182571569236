import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { from, of, switchMap } from 'rxjs';
import { PICFLOW_STORYBLOK } from '../picflow-storyblok';
import { PromotionService } from '../services/promotion.service';
import { StorageService } from '../services/storage.service';

export const storyblokPromotionResolver: ResolveFn<unknown> = route => {
  const promotionService = inject(PromotionService);
  const storage = inject(StorageService);

  const path = route.url.map(segment => segment.path).join('/') || 'home';
  const storyblokService = inject(PICFLOW_STORYBLOK);
  return storyblokService.getStory(path, route).pipe(
    switchMap(resp => {
      const story = resp && resp.story;
      if (!story) return of(undefined);
      const promoCode = route.queryParams.promo || storage.get('promo');
      if (!promoCode) return of(undefined);
      return from(promotionService.checkCode(promoCode));
    })
  );
};
