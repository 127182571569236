import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { PICFLOW_STORYBLOK } from '../picflow-storyblok';

export const storyblokPricingBoxContentResolver: ResolveFn<unknown> = route => {
  const storyblokService = inject(PICFLOW_STORYBLOK);
  return storyblokService.getUniqueComponentsList(route).pipe(
    switchMap(components => {
      if (components.has('PricingBoxList')) {
        return storyblokService.getStories({ content_type: 'PricingBox' }, route);
      }
      return of(undefined);
    })
  );
};
