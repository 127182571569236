import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class TrackingIdGuard {
  private storage = inject(StorageService);
  private platformId = inject<string>(PLATFORM_ID);

  canActivate(next: ActivatedRouteSnapshot): true {
    if (!isPlatformBrowser(this.platformId)) return true;
    if (next.queryParams._cio_id) {
      this.storage.set('_cio_id', next.queryParams._cio_id, true);
    }
    return true;
  }
}
