import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, map, merge, of, switchMap } from 'rxjs';
import { PICFLOW_STORYBLOK } from '../../picflow-storyblok';
import { StoryblokRootComponent } from '../components/storyblok-root/storyblok-root.component';

@Component({
  selector: 'app-storyblok-page',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './storyblok-page.component.html',
  imports: [StoryblokRootComponent, AsyncPipe],
})
export class StoryblokPageComponent {
  private route = inject(ActivatedRoute);
  private storyblokService = inject(PICFLOW_STORYBLOK);

  story$ = merge(this.route.data, this.storyblokService.updates$.pipe(filter(Boolean))).pipe(map(data => data?.story));

  blurOverlay$ = this.story$.pipe(map(story => story && story.content.blurOverlay));
  hasHomeTeaser$ = this.story$.pipe(map(story => story && story.content.body?.[0]?.component === 'HomeTeaser'));
  prefooter$ = this.story$.pipe(map(story => story && story.content.preFooter?.[0]));
  topBlur$ = this.story$.pipe(map(story => story && story.content.topBlur));
  topBlurMaxHeight$ = this.story$.pipe(map(story => story && story.content.topBlurMaxHeight));
  verticalDottedLines$ = this.story$.pipe(map(story => story && story.content.verticalDottedLines));
  darkHeader$ = this.hasHomeTeaser$.pipe(
    switchMap(hasHomeTeaser => {
      if (hasHomeTeaser) return of(true);
      return this.story$.pipe(
        map(story => {
          if (['grey-80'].includes(story.content?.topGradient)) return true;
          return ['dark', 'black'].includes(story && story.content.background);
        })
      );
    })
  );

  extraDataMap$ = this.route.data.pipe(
    switchMap(() =>
      this.story$.pipe(
        map(story => {
          const map = new Map<string, { [key: string]: unknown }>();
          map.set('TableOfContents', {
            story,
          });
          map.set('ContentGroup', {
            extraDataMap: map,
          });
          return map;
        })
      )
    )
  );
}
